<template>
  <router-view
    :circle="circle"
    @view-another-circle="anotherCircle"
    @update-circle="updateCircle"
    @remove-mod="removeMod"
    @add-mod="addMod"
  ></router-view>
</template>

<script>
import http from '@/plugins/http';
export default {
  name: 'CirclesHome',
  data() {
    return {
      circle: {}
    };
  },
  methods: {
    getCircle(circleSlug) {
      const token =
        this.$store.getters.token || window.localStorage.getItem('bubbl_ut');
      let headers = {
        Authorization: `token ${token}`
      };

      if (!token) {
        headers = {};
      }

      http
        .get(`/v1/circle/${circleSlug}/`, { headers })
        .then(response => {
          let regular_moderators = response.data.regular_moderators.map(
            mod => mod.username
          );

          let circle = { ...response.data, regular_moderators };

          this.circle = circle;
          this.$store.dispatch('SET_CIRCLE', circle);
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.$router.push({
              name: '404',
              query: { lost: 'circle' },
              params: { '0': this.$route.path }
            });
            return;
          }
          this.$store.dispatch('SHOW_TOAST', {
            message: 'Error getting circle, please try again :(',
            style: 'error'
          });
          console.log('Error ! =>', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    anotherCircle(name) {
      console.log('Now viewing => ', name);
    },
    updateCircle(circle) {
      this.circle = circle;
      this.$store.dispatch('SET_CIRCLE', circle);
    },
    removeMod(mod) {
      const deleted = this.circle.regular_moderators.splice(
        this.circle.regular_moderators.indexOf(mod),
        1
      );

      console.log('deleted => ', deleted);

      this.$store.dispatch('SET_CIRCLE', this.circle);
    },
    addMod(mod) {
      this.circle.regular_moderators.push(mod);

      this.$store.dispatch('SET_CIRCLE', this.circle);
    }
  },

  mounted() {
    const circleSlug = this.$route.params['circle_slug'];

    this.getCircle(circleSlug);
  }
};
</script>

<style scoped></style>
